/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

export function getMonthDay(dateValue) {
  const date = new Date(dateValue);
  const month = date.toLocaleString('fr-FR', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  
  const dateObj = {
    month: month,
    day: day,
    year: year
  }

  return dateObj;
}